import React from "react"
import Cloud from "../images/cloud.png"
import Footer from "../components/footer"
import Layout from "../components/layout"
import ImageContainer from "../components/imageContainer"

const Style = {
  MainDiv: "gradientHome relative flex pt-24 sm:pt-40 lg:pt-48",
  TitleSection: "container text-center text-white mb-32 lg:mb-64",
  Title: "plus-bold text-4xl sm:text-5xl lg:text-6xl ",
  Subtitle: "frk-regular text-xl sm:text-2xl",
  ListCont: "container mt-16 mb-40 lg:mb-56 text-primary text-lg frk-regular bg-white",
  ListText: "mb-4",
}

const Terms = () => {
  return (
    <Layout title="Terms Of Services" link="/terms-of-service">
      <section className={Style.MainDiv}>
        <ImageContainer
          container="w-full absolute self-end"
          src={Cloud}
          alt="cloud"
        />
        <div className={Style.TitleSection}>
          <h2 className={Style.Title}>Terms & Conditions</h2>
          <p className={Style.Subtitle}>Last modification: May 22, 2019</p>
        </div>
      </section>
      <ul className={Style.ListCont}>
        <li className={Style.ListText}>
          1. These Terms & Conditions apply to all HelloBuild LLC (Build) apps
          released on both Apple Store (for iPhone, iPad) and Google Store (for
          all Android devices).
        </li>
        <li className={Style.ListText}>
          2. Any usage of the Build apps is bound by the Terms & Conditions,
          including any further modifications or features added to the apps in
          the future.
        </li>
        <li className={Style.ListText}>
          3. Build offers its mobile apps to the user “as-is” (see item 14).
          Still, Build always aim to deliver reliable and functional apps. For
          unexpected bugs that might cause issues, the user accepts that the
          only remedy is to wait for an updated version of the app.
        </li>
        <li className={Style.ListText}>
          4. Users agree to provide Build reliable, valid, and accurate
          information when asked for it on the app. Users also agree to make
          reasonable efforts to keep their information current on each app.
        </li>
        <li className={Style.ListText}>
          5. All users of the Build apps confirm and agree that they are legally
          authorized to use the apps in agreement with their local, applicable
          laws.
        </li>
        <li className={Style.ListText}>
          6. Users of the apps agree to comply to US-based copyright laws in
          relation to the usage of Build designs, brands, IPs (intellectual
          properties), and other items that might be covered by such laws.
        </li>
        <li className={Style.ListText}>
          7. Users retain property of their data entered into any Build app,
          Build retains property of all apps, including but not limited to
          designs, methods, and services it has assembled, created, and offered
          to users.
        </li>
        <li className={Style.ListText}>
          8. Users will be able to download their data at anytime, in the CSV
          (comma-separated values) format, to the extent that Build can make
          such data downloadable, using reasonable technical efforts. Users are
          also free to delete their accounts and all data, at anytime.
        </li>
        <li className={Style.ListText}>
          9. Users that engage in subscription (paid) services with Build will
          do that via the app stores of Apple and Google, in compliance to all
          the terms of each digital store, including but not limited agreeing to
          pay all applicable local taxes on purchases.
        </li>
        <li className={Style.ListText}>
          10. Builds reserves the right to stop offering any of its apps at
          anytime, and might not disclose the reason for this decision. Build
          will make all reasonable efforts to inform the users before an app is
          discontinued.
        </li>
        <li className={Style.ListText}>
          11. In the event of Build being acquired (change of ownership), the
          new owners might make changes to the Terms & Conditions. Build will
          make all reasonable efforts to inform its users before any acquisition
          takes place.
        </li>
        <li className={Style.ListText}>
          12. Build liability to any user, in any event, can not exceed the sum
          of the fees paid by such user to Build during the last 12 months of
          usage of the app in question.
        </li>
        <li className={Style.ListText}>
          13. By downloading and using the apps users agree to hold Build
          harmless of any loss, damage, or any other mishaps, related to the
          usage of its apps. The user solely assume all and any risks associated
          to the usage of any of the Build apps. This includes, but is not
          limited to, financial loss, lost of profits, or any type of personal
          damage or loss.
        </li>
        <li className={Style.ListText}>
          14. (This is the paragraph where we bring in some heavy legal jargon.
          Sorry, but someone told us we needed to.) Build makes no
          representation, warranty, or guaranty as to the reliability,
          timeliness, quality, suitability, truth, availability, accuracy or
          completeness of the functionalities provided by the apps or any
          content in there or generated by the apps. Build does not represent or
          warrants that (A) the use of the apps will be always secure, timely,
          uninterrupted or error-free or operate in combination with any other
          hardware, software, system or data, (B) the apps will meet any user
          requirements or expectations, © any provided data will be always
          accurate or reliable, (D) the quality of any products, services,
          information, or other material purchased or obtained by users through
          the apps will meet any user requirements or expectations, (E) bugs,
          errors or defects will be corrected in any set timeframe, or (F) the
          hosting servers that make the apps available are free of viruses or
          other harmful components. All Build apps and all of its content is
          provided to users strictly on an “as is” basis. All other conditions,
          representations and warranties, whether express, implied, statutory or
          otherwise, including, without limitation, any implied warranty of
          merchantability, fitness for a particular purpose, or non-infringement
          of third party rights, are hereby disclaimed by Build to the maximum
          extent permitted by applicable law.
        </li>
        <li className={Style.ListText}>
          15. The Terms & Conditions described here will ultimately be governed
          by and construed in accordance with the laws of the State of Florida,
          and controlling United States federal law, without regard to choice of
          law rules. Any dispute, controversy, or claim arising under or related
          to the Build apps will be brought exclusively in a Florida Court, as
          appropriate. All users of the apps submit to the personal jurisdiction
          and venue of these courts.
        </li>
        <li className={Style.ListText}>
          16. Build reserves the right to modify the Terms & Conditions at
          anytime. Build will make all reasonable efforts to inform the users
          when changes are made.
        </li>
        <li className={Style.ListText}>
          17. The Terms & Conditions are in effect when and in the format posted
          publicly on the Build website.
        </li>
      </ul>
      <Footer />
    </Layout>
  )
}

export default Terms
